<template>
  <div class="mng-container">
    <mng-header :page-title="isEdit?'编辑系统接入':'新增系统接入'" msg="">
      <el-button size="small" @click="goBack">取 消</el-button>
      <el-button type="primary" size="small" @click="submitBtn" :loading="submitLoading">提 交</el-button>
    </mng-header>
    <div class="mng-body">
      <div class="form-area">
        <el-form :model="dataForm" :rules="rules" ref="dataForm" label-suffix=":" label-width="100px">
          <el-form-item label="系统名称" prop="serviceName">
            <el-input v-model="dataForm.serviceName" maxlength="100" show-word-limit clearable></el-input>
          </el-form-item>
          <el-form-item label="跳转路径" prop="servicePath">
            <el-input v-model="dataForm.servicePath" maxlength="100" show-word-limit clearable></el-input>
          </el-form-item>
          <el-form-item label="系统描述" prop="description">
            <el-input type="textarea" rows="5" v-model="dataForm.description" maxlength="200" show-word-limit clearable></el-input>
          </el-form-item>
          <el-form-item label="启用状态" prop="region">
            <el-switch
              v-model="dataForm.status"
              active-value="1"
              inactive-value="0">
            </el-switch>
          </el-form-item>
          <el-form-item label="系统图标" prop="serviceImgPath">
            <div v-if="dataForm.serviceIconPath" class="upload-img-area">
              <el-image
                style="max-width: 140px; max-height: 140px"
                :src="dataForm.serviceIconPath">
              </el-image>
              <el-button type="text" icon="el-icon-delete" class="delImg" @click="delHadSelect('serviceIconPath')">删除</el-button>
            </div>
            <el-upload
              v-else
              class="img-uploader"
              action=""
              :multiple="false"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="beforeIconChange">
              <el-button type="text" icon="el-icon-upload">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="背景图片" prop="serviceImgPath">
            <div v-if="dataForm.serviceImgPath" class="upload-img-area">
              <el-image
                style="max-width: 140px; max-height: 140px"
                :src="dataForm.serviceImgPath">
              </el-image>
              <el-button type="text" icon="el-icon-delete" class="delImg" @click="delHadSelect('serviceImgPath')">删除</el-button>
            </div>
            <el-upload
               v-else
              class="img-uploader"
              action=""
              :multiple="false"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="beforeImgChange">
              <el-button type="text" icon="el-icon-upload">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import MngHeader from '@/components/mng-pages/MngHeader'
import { addSa, editSa, sADetail } from '@/service/api/sys-access/system-access'
import { checkTextDesc } from '@/util/validate/text-validator'
import { fileDel, fileUpload } from '@/service/api/file/file-api'
export default {
  name: 'SystemAccessMng',
  components: {
    MngHeader
  },
  props: {
    pageData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    const checkTitle = (rules, value, callback) => {
      return checkTextDesc(true, '系统名称', rules, value, callback, 2, 100)
    }
    const checkBrief = (rules, value, callback) => {
      return checkTextDesc(false, '跳转路径', rules, value, callback, 2, 200)
    }
    return {
      isEdit: false,
      submitLoading: false,
      loadingNoteTypeList: false,
      noteTypeList: [],
      // 表单数据
      dataForm: {
        serviceName: '',
        servicePath: '',
        status: '',
        description: '',
        serviceIconPath: '',
        serviceImgPath: ''
      },
      serviceIconPath: '',
      serviceImgPath: '',
      rules: {
        serviceName: [{ required: true, validator: checkTitle, trigger: 'change' }],
        servicePath: [{ required: true, validator: checkTitle, trigger: 'change' }],
        description: [{ required: false, validator: checkBrief, trigger: 'change' }],
        noticeType: [{ required: true, message: '请选择公告类型', trigger: 'change' }],
        noticeDetails: [{
          required: true,
          validator: (rules, value, callback) => {
            console.log(value)
            const exp = /^(<p><br><\/p>)$/
            const exp2 = /^(<p>[<br>|&nbsp;|\s]*<\/p>)+$/
            if (!value || exp.test(value)) {
              this.$message.error('请输入公告内容')
            } else if (exp2.test(value)) {
              this.$message.error('公告内容不能为空白字符（空格，换行符等）')
            } else {
              return callback()
            }
          },
          trigger: 'change'
        }]
      },
      // 编辑器配置
      toolbarConfig: {
        excludeKeys: ['fullScreen', 'uploadVideo', 'uploadImage']
      },
      editorConfig: {
        readOnly: false
      }
    }
  },
  mounted() {
    if (this.pageData && this.pageData.id) {
      this.isEdit = true
      this.getNoteDetail(this.pageData.id)
    }
  },
  methods: {
    goBack() {
      this.$emit('changePage', { pageId: 'SystemAccessTable' })
    },
    submitBtn() {
      this.$refs.dataForm.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.isEdit) {
            editSa(this.dataForm).then(res => {
              this.submitLoading = false
              if (res.data.status === 200) {
                this.$message.success(res.data.msg || '编辑成功！')
                this.goBack()
              } else {
                this.$message.error(res.data.msg || '编辑失败！')
              }
            }).catch(e => {
              this.submitLoading = false
              this.$message.error('服务异常。')
            })
          } else {
            addSa(this.dataForm).then(res => {
              this.submitLoading = false
              if (res.data.status === 200) {
                this.$message.success(res.data.msg || '系统接入成功！')
                this.goBack()
              } else {
                this.$message.error(res.data.msg || '系统接入失败！')
              }
            }).catch(e => {
              this.submitLoading = false
              this.$message.error('服务异常。')
            })
          }
        }
      })
    },
    checkImg(file) {
      const typeList = ['image/png', 'image/jpeg']
      const isJPG = typeList.some(item => item === file.raw.type)
      const isLt2M = file.size / 1024 / 1024 <= 2

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return false
      }
      return true
    },
    // 系统图标上传
    beforeIconChange(file) {
      if (this.checkImg(file)) {
        const uploadFormData = new FormData()
        uploadFormData.append('file', file.raw)
        fileUpload(uploadFormData).then(res => {
          if (res.data.status === 200) {
            this.dataForm.serviceIconPath = res.data.data[0].url
            this.serviceIconPath = res.data.data[0].url
          } else {
            this.dataForm.serviceIconPath = ''
            this.serviceIconName = ''
            this.$message.error(res.data.msg || '上传失败！')
          }
        }).catch(e => {
          console.log(e)
          this.dataForm.serviceIconPath = ''
          this.serviceIconName = ''
          this.$message.error('上传失败！')
        })
      }
    },
    // 系统背景图片上传
    beforeImgChange(file) {
      if (this.checkImg(file)) {
        const uploadFormData = new FormData()
        uploadFormData.append('file', file.raw)
        fileUpload(uploadFormData).then(res => {
          if (res.data.status === 200) {
            this.dataForm.serviceImgPath = res.data.data[0].url
            this.serviceImgPath = res.data.data[0].url
          } else {
            this.dataForm.serviceImgPath = ''
            this.serviceImgName = ''
            this.$message.error(res.data.msg || '上传失败！')
          }
        }).catch(e => {
          console.log(e)
          this.dataForm.serviceImgPath = ''
          this.serviceImgName = ''
          this.$message.error('文件上传失败！')
        })
      }
    },
    // 删除已选择
    delHadSelect(attr) {
      this.dataForm[attr] = ''
      const params = {
        filePaths: attr.includes('Icon') ? this.serviceIconPath : this.serviceImgPath
      }
      fileDel(params).then(res => {
        console.log(res)
      }).catch(e => {
        console.log(e)
      })
    },
    // 获取公告详情
    getNoteDetail(id) {
      this.detailLoading = true
      sADetail({ id: this.pageData.id }).then(res => {
        this.detailLoading = false
        if (res.data.status === 200) {
          this.dataForm = res.data.data
          this.serviceIconPath = this.dataForm.serviceIconPath
          this.serviceImgPath = this.dataForm.serviceImgPath
        } else {
          this.$message.error(res.data.msg || '获取系统接入详情失败！')
        }
      }).catch(e => {
        console.log(e)
        this.detailLoading = false
        this.$message.error('服务异常！')
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/common/mng-pages.scss";
.upload-img-area{
  .delImg{
    color: #e8413d;
    padding: 2px;
    &:hover{
      border: 1px solid;
    }
  }
}
</style>
